<template>
  <div class="invoices" ref="passiveInvoices">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>Fatture passive</h4>
        </div>
        <div class="col d-flex justify-content-end">
          <button class="btn btn-fin mr-3" @click="selectedRow = null; togglePopup('upsertInvoice')">Aggiungi fattura</button>
          <button class="btn btn-fin mr-3" @click="selectedRow = null; togglePopup('importInvoice')">Importa</button>
          <FiltersInvoices />
        </div>
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="invoicesTable"
          ref="invoicesTable"
          :items="invoices"
          :fields="fields"
          no-local-sorting
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hover
          show-empty
        >
          <template #cell(bank)="row">
            <div class="d-flex align-items-center">
              <img v-if="getLogo(row.item?.bank)" :src="getLogo(row.item?.bank)" class="bank-logo md mr-2">
              <div :class="`text-bank-variant-${row.item.bank.colour ? row.item.bank.colour : 0}`" class="font-weight-600">
                {{ row.item.bank.alias ? row.item.bank.alias : row.item.bank.name }}
              </div>
            </div>
          </template>
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(scadenze)="row">
            <div v-for="(scadenza, i) in row.item.scadenze" :key="`row_${row.index}_date_${i}`" class="font-weight-bolder">
              {{ scadenza ? toFormattedDate(scadenza) : ''}}
            </div>
          </template>
          <template #cell(total)="row">
            <CellContent
              :value="parseFloat(row.item.total)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(residuo)="row">
            <CellContent
              :value="parseFloat(row.item.residuo)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="
                  togglePopup('upsertInvoice');
                  selectedRow = row.item;
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  togglePopup('deleteInvoice');
                  selectedRow = row.item;
                "
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessuna fattura trovata
            </div>
          </template>
        </b-table>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage2"
            :total-rows="totalItems"
          ></b-pagination>
        </div>
      </div>
    </div>

    <Popup @beforeClosing="confirmInvoice = false;" ref="upsertInvoice" class="xl up">
      <template #fullContent>
        <UpsertInvoiceWrapper :banks="banks" :companyId="companyId" :invoice="selectedRow" :fatturePassive="true" :confirmInvoice="confirmInvoice" @toggleSnackbar="toggleSnackbar(msg)" @done="onDone"/>
      </template>
    </Popup>

    <Popup ref="deleteInvoice" class="md up">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        La fattura n. <b>{{ selectedRow.number }}</b> di {{ (selectedRow.total).toFixed(2) }} € emessa da {{selectedRow.denomination}} verrà eliminata.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <!-- Drag n drop invoice -->
    <Popup ref="importInvoice" class="lg import-invoice-popup" noBody @beforeClosing="errorMessage = '';">
      <template #fullContent>
        <h4 class="mb-4">Importa fatture passive</h4>

        <div class="row mb-4">
          <div class="col-12">
            <div class="selector" id="selector">
              <div class="row">
                <div class="col-6">
                  <button class="btn w-100 h-100 option" @click="defaultView = true;" :class="{ active: defaultView === true }">
                    Importa
                  </button>
                </div>
                <div class="col-6">
                  <div class="notification-dot" v-if="uploadedInvoices.length > 0">
                    {{uploadedInvoices.length}}
                  </div>
                  <button class="btn w-100 h-100 option" @click="defaultView = false; errorMessage = '';" :class="{ active: defaultView !== true }">
                    In attesa di conferma
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="defaultView">
          <DragAndDrop v-if="!uploadingData" @uploadFile="uploadFile"/>
          <div v-if="uploadingData">
            <div class="d-flex justify-content-center align-items-center p-5">
              <i class="fas fa-spinner spinning mr-2"></i>
              Loading
            </div>
          </div>

          <ErrorCard v-if="errorMessage">
            <template #message >
            {{ errorMessage }}
            </template>
          </ErrorCard>
        </div>

        <div v-else>
          <div v-if="uploadedInvoices.length === 0" class="text-center">
            Non ci sono fatture da confermare
          </div>
          <div v-else>
            <b-table
              id="uploadedInvoices"
              ref="uploadedInvoices"
              :items="uploadedInvoices"
              :fields="fields2"
            >
              <template #head(select)>
                <b-form-checkbox :id="`checkbox-head`" v-model="selectAll"></b-form-checkbox>
              </template>
              <template #cell(select)="row">
                <b-form-checkbox :id="`checkbox-${row.item.id}`" :value="row.item.id" v-model="invoicesToConfirm"></b-form-checkbox>
              </template>
              <template #cell(date)="row">
                {{ row.item.date ? toFormattedDate(row.item.date) : null }}
              </template>
              <template #cell(type)="row">
                {{ row.item.type | movement | capitalizeFirstLetter }}
              </template>
              <template #cell(total)="row">
                <CellContent
                  :value="parseFloat(row.item.total)"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
              </template>
              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-fin-sm p-0" @click="selectedRow = row.item; confirmInvoice = true; togglePopup('upsertInvoice')"><i class="fas fa-pen btn-fin"></i></button>
                  <button class="btn btn-fin-sm p-0" @click="selectedRow = row.item; togglePopup('deleteInvoice')"><i class="fas fa-trash btn-fin-red"></i></button>
                </div>
              </template>
            </b-table>

            <custom-button
              :isLoading="loading2"
              :label="`Conferma selezionate (${invoicesToConfirm.length})`"
              class="mt-4 w-100"
              @click.prevent.native="onConfirm"
            />

            <ErrorCard v-if="errorMessage2">
              <template #message >
              {{ errorMessage }}
              </template>
            </ErrorCard>

          </div>
        </div>

      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" class="up"/>
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import { getFileExtension } from '@/helpers/files.js';
import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
} from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
// to do handle broken img links

import { toFormattedDate } from '@/helpers/dates.js';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import DragAndDrop from '@/views/components/DragAndDrop.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import FiltersInvoices from '@/views/components/FiltersInvoices.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import UpsertInvoiceWrapper from '@/views/components/Forms/Invoices/UpsertInvoiceWrapper.vue';

export default {
  components: {
    'b-form-group': BFormGroup,
    'b-form-select': BFormSelect,
    'b-table': BTable,
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem,
    'b-pagination': BPagination,
    'custom-button': Button,
    CellContent,
    DragAndDrop,
    ErrorCard,
    FiltersInvoices,
    Loader,
    Popup,
    Snackbar,
    UpsertInvoiceWrapper,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        { key: 'number', label: 'Fattura', sortable: false },
        { key: 'bank', label: 'banca principale', sortable: false },
        {
          key: 'date',
          label: 'Data',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'denomination', label: 'Fornitore', sortable: false },
        { key: 'total', label: 'Totale fattura', sortable: false, class: 'text-right' },
        { key: 'residuo', label: 'Al saldo', sortable: false, class: 'text-right' },
        { key: 'scadenze', label: 'Scadenze', sortable: true, class: 'text-right' },
        { key: 'actions', label: '', sortable: false },
      ],
      fields2: [
        { key: 'select', label: '', sortable: false },
        // aggiungere banca ?
        {
          key: 'date',
          label: 'Data',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'number', label: 'N. fattura', sortable: true },
        { key: 'denomination', label: 'Controparte', sortable: true },
        { key: 'total', label: 'Totale fattura', sortable: true, class: 'text-right' },
        { key: 'actions', label: '', sortable: false },
      ],
      invoices: [],

      // Uploaded Files
      uploadedInvoices: [],
      invoicesToConfirm: [],
      confirmInvoice: false,
      selectAll: false,
      errorMessage2: '',

      errorMessage: '',
      showPopup: true,
      snackbarText: '',
      loading: false,
      loading2: false,
      isLoadingPage: true,
      uploadingData: false,
      defaultView: true,

      rowColour: null,
      textColour: null,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,

      // Table
      isBusy: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || null, // default: ultime aggiunte (non c'è filtro)
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPage2: null,
      totalItems: null,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],

      selectedRow: null,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
    watchedSorting () {
      return this.sortBy + this.sortDesc;
    },
  },
  watch: {
    $route: function () {
      this.getInvoices();
    },
    selectAll () {
      if (this.selectAll) {
        this.invoicesToConfirm = this.uploadedInvoices.map(invoice => invoice.id);
      } else {
        this.invoicesToConfirm = [];
      }
    },
    perPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            perPage: this.perPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    currentPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    selectedBank: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            bankId: this.selectedBank.id,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        })
        .finally(() => {
        });
    },
    startDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            startDate: this.startDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    endDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            endDate: this.endDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    watchedSorting: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            sortDesc: this.sortDesc,
            sortBy: this.sortBy,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    toggleSnackbar (msg) {
      this.snackbarText = msg;
      this.$refs.snackbar.openSnackbar();
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
          this.selectedBank = this.searchInBanks(this.bankId);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getInvoices () {
      this.isBusy = true;
      const filters = this.$route.query;
      this.$api
        .getListInvoices(this.companyId, filters, 'out')
        .then((res) => {
          this.$log.debug(res.data);
          this.totalItems = res.data.metadata['total-items'];
          this.perPage2 = res.data.metadata['per-page'];

          this.invoices = res.data.fatture || [];
          this.uploadedInvoices = res.data.notConfirmed || [];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.isLoadingPage = false;
        });
    },
    getLogo (bank) {
      if (bank) {
        const foundBank = this.banks.find(b => b.id === bank.id);
        return foundBank.logo ? foundBank.logo : bankLogoDefault;
      } else {
        return bankLogoDefault;
      }
    },
    onConfirm () {
      this.loading2 = true;

      const promises = this.invoicesToConfirm.map(id => this.$api.confirmInvoice(id));
      this.$log.debug(promises);

      Promise.all(promises)
        .then((res) => {
          res.map(el => {
            const i = this.invoicesToConfirm.indexOf(el.data.id);
            this.invoicesToConfirm.splice(i, 1);
          });
        })
        .catch((e) => {
          this.$log.debug(e);
        })
        .finally(() => {
          this.getInvoices();
          this.loading2 = false;
        });
    },
    onDelete () {
      this.loading = true;
      this.$api
        .deleteInvoice(this.selectedRow.id)
        .then(() => {
          this.togglePopup('deleteInvoice');
          this.snackbarText = 'Fattura eliminata correttamente';
          this.$refs.snackbar.openSnackbar();
          this.getInvoices();
          this.uploadedInvoices = this.uploadedInvoices.filter(file => file.id !== this.selectedRow.id);
        })
        .catch((e) => {
          this.$log.debug(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit () {
      this.getInvoices();
      this.togglePopup('movementEditor');
      this.snackbarText = 'Modifiche salvate';
      this.$refs.snackbar.openSnackbar();
    },
    onDone (type = 'edit') {
      if (type === 'confirm') {
        this.snackbarText = 'Fattura confermata correttamente';
      } else if (type === 'save') {
        this.snackbarText = 'Fattura aggiunta correttamente';
      } else if (type === 'edit') {
        this.snackbarText = 'Fattura modificata correttamente';
      }
      this.togglePopup('upsertInvoice');
      this.getInvoices();
      this.$refs.snackbar.openSnackbar();
      // to do edit
      this.invoicesToConfirm = [];
    },
    scrollToTop () {
      setTimeout(() => {
        this.$refs.movements.scrollTo({
          top: 250,
          left: 0,
          behavior: 'smooth',
        });
      }, 500);
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    uploadFile (file) {
      this.errorMessage = '';
      const extension = getFileExtension(file.name);

      if (extension === 'xml') {
        this.uploadXml(file);
      } else if (extension === 'zip') {
        this.uploadZip(file);
      } else {
        this.errorMessage = 'File non supportato';
      }
    },
    uploadXml (file) {
      this.uploadingData = true;
      this.$api.uploadXmlInvoice(this.companyId, file, 'out')
        .then((res) => {
          this.$log.debug(res);
          if (res.data.msg) {
            this.errorMessage = res.data.msg;
          } else {
            this.uploadedInvoices.unshift(Object.assign(res.data));
            this.defaultView = false;

            this.confirmInvoice = true;
            this.selectedRow = res.data;
            this.togglePopup('upsertInvoice');
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = 'Il caricamento non è andato a buon fine. Controlla la partita IVA nei file o contattaci se il problema persiste.';
        })
        .finally(() => {
          this.uploadingData = false;
        });
    },
    uploadZip (file) {
      this.uploadingData = true;
      const formData = new FormData();
      formData.append('fatture', file);

      this.$api.uploadZipInvoice(this.companyId, formData, 'out')
        .then((res) => {
          this.$log.debug(res);
          if (res.data.messages.length > 0) {
            this.errorMessage = 'Il caricamento di una o più fatture non è andato a buon fine. Controlla la partita IVA nei file o contattaci se il problema persiste.';
          } else {
            this.defaultView = false;
            res.data.fatture.map(fattura => {
              this.uploadedInvoices.unshift(fattura);
            });
          }
        })
        .catch((e) => {
          this.errorMessage = 'Il caricamento di una o più fatture non è andato a buon fine. Controlla la partita IVA nei file o contattaci se il problema persiste.';
          this.$log.error(e);
        })
        .finally(() => {
          this.uploadingData = false;
        });
    },
  },
  mounted () {
    // this.$router.replace({ query: Object.assign({}, this.$route.query, { 'types[]': ['da saldare'] }) })
    //   .catch(() => {});

    this.getCompanyInfo();
    this.getInvoices();
  },
};
</script>

<style lang="scss">
.no-bg {
  background: transparent !important;
}

.import-invoice-popup {
  // to do altezza tra card e bordo in alto rimane la stessa al cambiare dell'altezza della card
  &.container {
    // cose
  }
  &.card {
    // cose
  }
}

.invoices {
  background: $primary-lighter;

  .up {
    z-index: 30;
  }

  .spinning {
    font-size: 18px;
  }

  // selected bank box
  .box {
    border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
      &:nth-last-child(2) {
        text-align: right;
      }
    }
    thead {
      white-space: nowrap;
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }
}
</style>
