<template>
  <div class="movements" ref="movements">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>Scadenzario attivo</h4>
        </div>
        <div class="col-auto d-flex justify-content-end">
          <b-dropdown
              size="sm"
              id="export"
              text="Esporta"
              right
              toggle-class="text-decoration-none"
              class="btn-export"
          >
              <b-dropdown-item @click="download('pdf')">PDF</b-dropdown-item>
              <b-dropdown-item @click="download('xls')">XLS</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="col-auto d-flex justify-content-end">
          <Filters noSearch noTypes />
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-2 d-flex flex-row">
          <b-form-group class="w-100">
            <label for="date-picker">Inizio</label>
            <b-form-input
              type="date"
              id="date-picker"
              v-model="startDate"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2 d-flex flex-row">
          <b-form-group class="w-100">
            <label for="date-picker">Fine</label>
            <b-form-input
              type="date"
              id="date-picker"
              v-model="endDate"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-5 d-flex flex-column ml-auto">
          <b-form-group>
            <label for="search-input">Cerca</label>
            <b-form-input
              id="search-input"
              v-model="searchInput"
              placeholder="Cliente, Banca, tipo, o descrizione"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="table"
          ref="table"
          :items="movements"
          :fields="fields"
          no-local-sorting
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hover
          show-empty
        >
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(type)="row">
            {{ row.item.type | movement | capitalizeFirstLetter }}
          </template>
          <template #cell(amount)="row">
            <CellContent
              :value="parseFloat(row.item.amount)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="
                  togglePopup('movementEditor');
                  selectedRow = row.item;
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  togglePopup('movementDelete');
                  selectedRow = row.item;
                "
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessun risultato trovato
            </div>
          </template>
        </b-table>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage2"
            :total-rows="totalItems"
          ></b-pagination>
        </div>
      </div>
    </div>

    <!-- popup edit -->
    <Popup ref="movementEditor" class="md">
      <template #fullContent>
        <EditMovement :obj="selectedRow" :banks="banks" @save="onEdit" />
      </template>
    </Popup>

    <Popup ref="movementDelete" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il movimento <b>{{ selectedRow.type | movement}}</b> di
        <b>{{ toCurrency(selectedRow.amount) }} €</b> verrà eliminato
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" />
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import { toCurrency } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BTable,
} from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
// to do handle broken img links

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import EditMovement from '@/views/components/Forms/Movements/EditMovement.vue';
import Filters from '@/views/components/Filters.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    'custom-button': Button,
    CellContent,
    EditMovement,
    Filters,
    Loader,
    Popup,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        { key: 'bankName', label: 'Banca', sortable: true },
        {
          key: 'date',
          label: 'Scadenza',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'type', label: 'Tipo', sortable: false },
        { key: 'description', label: 'Descrizione', sortable: false },
        { key: 'counterpart', label: 'Controparte', sortable: false },
        { key: 'amount', label: 'Importo', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ],
      movements: [],

      showPopup: true,
      snackbarText: '',
      loading: false,
      isLoadingPage: true,

      rowColour: null,
      textColour: null,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,
      searchInput: this.$route.query.search || null,

      // Table
      isBusy: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || 'date',
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPage2: null,
      totalItems: null,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],

      selectedBank: null,
      selectedRow: null,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
  },
  watch: {
    $route: function () {
      if (!this.isLoadingPage) {
        this.getMovements();
      }
    },
    perPage: function () {
      this.setFilters();
    },
    currentPage: function () {
      this.setFilters();
    },
    selectedBank: function () {
      this.setFilters();
    },
    startDate: function () {
      this.setFilters();
    },
    endDate: function () {
      this.setFilters();
    },
    watchedSorting: function () {
      this.setFilters();
    },
    sortBy () {
      this.setFilters();
    },
    sortDesc () {
      this.setFilters();
    },
    searchInput () {
      this.setFilters();
    }
  },
  methods: {
    download (type) {
      this.isBusy = true;
      const filters = this.$route.query;

      this.$api.downloadPrimeEntry(this.companyId, filters, type)
        .then((res) => {
          this.$log.debug(res.data);
          // create pdf
          window.open(URL.createObjectURL(res.data));
        })
        .catch((e) => {
          this.$log.error(e);
          // to do feedback per download fallito
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = [{ alias: 'Tutte' }, ...res.data.banks];
          this.selectedBank = this.getBankById(this.bankId);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getMovements () {
      this.isBusy = true;
      const filters = this.$route.query;
      this.$api
        .fetchScadenzarioAttivo(this.companyId, Object.assign({}, filters, { types: 'scadenzario attivo', }))
        .then((res) => {
          this.totalItems = res.data.metadata['total-items'];
          this.perPage2 = res.data.metadata['per-page'];
          this.movements = res.data.promises || [];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.updateColours();
          this.isLoadingPage = false;
        });
    },
    scrollToTop () {
      setTimeout(() => {
        this.$refs.movements.scrollTo({
          top: 250,
          left: 0,
          behavior: 'smooth',
        });
      }, 500);
    },
    getBankById () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    setFilters: function () {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { search: this.searchInput, page: this.currentPage, startDate: this.startDate, endDate: this.endDate, perPage: this.perPage, bankId: this.selectedBank?.id, sortDesc: this.sortDesc, sortBy: this.sortBy }),
      })
        .catch(() => {})
        .finally(() => {
          this.scrollToTop();
          this.updateColours();
        });
    },
    toCurrency (value) {
      return toCurrency(value);
    },
    toFormattedDate (date) {
      return toFormattedDate(date);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    updateColours () {
      this.rowColour = this.selectedBank?.colour
        ? `bank-variant-${this.selectedBank.colour}-light`
        : 'primary-light';
      this.textColour = this.selectedBank?.colour
        ? `text-bank-variant-${this.selectedBank.colour}`
        : 'text-primary';
    },
    replaceImg (e) {
      e.target.src = bankLogoDefault;
    },
  },
  mounted () {
    this.$root.$on('updateMovements', () => {
      this.getCompanyInfo();
      this.getMovements();
    });
    this.getCompanyInfo();
    this.getMovements();
  },
};
</script>

<style lang="scss">
.no-bg {
  background: transparent !important;
}
.movements {
  background: $primary-lighter;

  // selected bank box
  .box {
    border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }

  .items-found {
    cursor: default;
    display: flex;
    text-transform: lowercase;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    width: fit-content;
    border-radius: 8px;

    background: none;
    color: $primary;

    .number-box {
      padding: 3px;
      background: $primary-light;
      height: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 5px;
    }
  }
  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
      &:nth-last-child(2) {
        text-align: right;
      }
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }
}
</style>
