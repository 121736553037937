<template>
    <div class="drag-area" @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive" @dragover.prevent @drop.prevent="toggleActive(); saveFile($event)" @change="selectFile" :class="{ active: active}">
        <div class="row d-flex flex-column text-center p-4">
            <div class="col d-flex justify-content-center align-items-center mb-3">
                <i class="fas fa-upload"></i>
            </div>
            <div class="col d-flex justify-content-center align-items-center mb-3">
                {{ active ? 'Rilascia' : 'Trascina o clicca qui per importare un file .XML o uno zip di file .XML' }}
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <label v-show="!active" for="inputFile" class="btn btn-fin m-0">Sfoglia</label>
                <input v-show="!active" type="file" id="inputFile" class="d-none" accept=".xml, .zip"/>
            </div>
        </div>
        <!-- to do cancellare
        <div v-for="(file, i) in files" :key="`${i}_${file.name}`">
          {{file.name}}
        </div>
        -->
    </div>
</template>

<script>
export default {
  name: 'DragAndDrop',
  data () {
    return {
      files: null,
      active: false,
    };
  },
  methods: {
    toggleActive () {
      this.active = !this.active;
    },
    saveFile (e) {
      this.$log.debug('saveFile');
      this.$log.debug(e.dataTransfer.files);
      this.files = e.dataTransfer.files;
      this.$emit('uploadFile', this.files[0]);
    },
    selectFile (e) {
      this.$log.debug('selectFile');
      this.files = e.target.files;
      this.$log.debug(this.files);
      this.$emit('uploadFile', this.files[0]);
      // this.upload(this.files[0]);
    },
    /*
    uploadFile (file) {
      this.$log.debug(typeof file);
      this.$log.debug(file);

      const formData = new FormData();
      formData.append('file', file);

      this.$log.debug(typeof formData);
      this.$log.debug(formData);

      const companyId = '8a3a4315-3e1e-4557-98a9-6aaf26dae700';
      this.$api.uploadXmlInvoice(companyId, formData)
        .then((res) => {
          this.$log.debug(res);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    upload (file) {
      const companyId = '8a3a4315-3e1e-4557-98a9-6aaf26dae700';

      this.$api.uploadXmlInvoice(companyId, file)
        .then((res) => {
          this.$log.debug(res.data);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    */
  },
};
</script>

<style lang="scss">
.drag-area {
    border: 2px solid $primary-mid;
    border-radius: 10px;
    border-style: dashed;
    box-sizing: border-box;
    background: $primary-lighter;
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    .row {
        height: 250px;
    }
    &.active {
        filter: brightness(95%);
    }
    i {
        color: $primary;
        opacity: 0.7;
        font-size: 72px;
    }
}
</style>
